<template>
  <div class="flex justify-center items-center rounded-lg mx-2 md:mt-3 relative">
    <div
      class="md:w-8/12 lg:w-4/12 w-full md:mt-3 rounded-md h-auto md:p-0 px-4 text-white md:border md:border-gray-600"
    >
      <div class="py-12 h-auto">
        <div class="flex flex-row justify-center my-1.5">
          <img src="@/assets/headerLogo.webp" alt="logo" class="w-20" />
        </div>
        <div class="flex flex-row justify-center my-4">
          <h1 class="text-lg font-bold text-center text-gray-100 font-Tajawal">
            باقات المشاهدة
          </h1>
        </div>

        <div class="my-3 text-center flex justify-center">
          <p class="text-gray-400 md:text-md text-sm max-w-md text-center font-Tajawal">
            تأكد من إدخال نفس رقم الهاتف الذي تستخدمه داخل التطبيق
          </p>
        </div>
        <div class="flex justify-center items-center my-8 hidden">
          <div
            class="rounded-[25px] bg-[#111220] w-auto flex justify-center items-center"
          >
            <button
              v-for="tab in tabs"
              :key="tab.name"
              :class="['tab', { active: tab.name === activeTab }]"
              class="md:px-10 px-4 py-3 rounded-[20px] border-none cursor-pointer md:text-md md:text-md text-sm"
              @click="selectTab(tab.name)"
            >
              {{ tab.label }}
            </button>
          </div>
        </div>
        <div class="grid grid-cols-2 md:gap-6 gap-2 md:mx-3 my-3" dir="rtl" v-if="false">
          <div
            class="rounded-lg md:h-[300px] h-[200px] bg-[#131428] md:p-4 p-2 w-full flex flex-col items-center gap-2 cursor-pointer"
            :class="StripePlan == 'monthly' ? 'border border-[#9d2890]' : ' '"
            @click="selectedPlan('monthly')"
          >
            <h1
              class="text-center md:text-md md:text-md text-sm font-Tajawal"
              :class="StripePlan == 'monthly' ? 'text-[#9d2890]' : 'text-white'"
            >
              شهرياً
            </h1>
            <img
              class="rounded-lg w-full h-4/6 object-contain"
              src="@/assets/plan-1.png"
              width="100"
              height="100"
              alt="paln 1"
            />
            <h1
              class="text-center md:text-md md:text-md text-sm font-Tajawal"
              dir="rtl"
              :class="StripePlan == 'monthly' ? 'text-gray-400' : 'text-white'"
            >
              إبتداءً من 1.5 دولار
            </h1>
          </div>

          <div
            class="rounded-lg md:h-[300px] h-[200px] bg-[#131428] md:p-4 p-2 w-full flex flex-col items-center gap-2 cursor-pointer"
            :class="StripePlan == 'halfYear' ? 'border border-[#9d2890]' : ' '"
            @click="selectedPlan('halfYear')"
          >
            <h1
              class="text-center md:text-md md:text-md text-sm font-Tajawal"
              :class="StripePlan == 'halfYear' ? 'text-[#9d2890]' : 'text-white'"
            >
              6 اشهر
            </h1>
            <img
              class="rounded-lg w-full h-4/6 object-contain"
              src="@/assets/plan-1.png"
              width="100"
              height="100"
              alt="paln 1"
            />
            <h1
              class="text-center md:text-md md:text-md text-sm font-Tajawal"
              dir="rtl"
              :class="StripePlan == 'halfYear' ? 'text-gray-400' : 'text-white'"
            >
              8 دولار <span>{{ "(توفير 10%)" }}</span>
            </h1>
          </div>
          <div
            class="rounded-lg md:h-[300px] h-[200px] bg-[#131428] md:p-4 p-2 w-full flex flex-col items-center gap-2 cursor-pointer"
            :class="StripePlan == 'yearlyOption' ? 'border border-[#9d2890]' : ''"
            @click="selectedPlan('yearlyOption')"
          >
            <h1
              class="text-center md:text-md md:text-md text-sm font-Tajawal"
              :class="StripePlan == 'yearlyOption' ? 'text-[#9d2890]' : 'text-white'"
            >
              سنوياً
            </h1>
            <img
              class="rounded-lg w-full h-4/6 object-contain"
              src="@/assets/plan-2.png"
              width="100"
              height="100"
              alt="paln 1"
            />
            <h1
              class="text-center md:text-md md:text-md text-sm font-Tajawal"
              :class="StripePlan == 'yearlyOption' ? 'text-gray-400 ' : 'text-white'"
              dir="rtl"
            >
              15 دولار <span>{{ "(توفير 12%)" }}</span>
            </h1>
          </div>
        </div>
        <div
          class="flex flex-col justify-center items-center space-x-2 w-full md:my-3"
          v-else
        >
          <form class="md:w-8/12 w-full mx-auto" dir="">
            <div class="relative">
              <select
                dir="rtl"
                id="countries"
                v-model="operator"
                class="custom-select bg-gray-900 border border-[#9d2890] text-gray-200 md:text-md text-sm rounded-lg focus:ring-[#9d2890] focus:border-[#9d2890] block w-full p-2.5"
              >
                <option class="" value="sd" selected>السودان</option>
              </select>
            </div>
          </form>
          <div
            class="my-3 flex justify-end space-x-3 md:w-10/12 w-full md:px-12 px-1"
            v-if="operator === 'sd'"
          >
            <div
              class="form-group w-1/2 rounded-md p-6 relative bg-[#111220] flex flex-col items-center justify-center px-4 cursor-pointer"
              :class="selectedProvider == 'sudani' ? ' border-2 border-[#9d2890]' : ''"
              @click="showOperator(0)"
            >
              <span
                v-if="selectedProvider === 'sudani'"
                class="size-5 bg-[#9d2890] absolute top-2 right-2 rounded-full text-center md:text-md text-sm"
                ><span class="mt-3">{{ "✓" }}</span></span
              >
              <label for="sudani" class="custom-radio-label mx-2">سوداني </label>
              <div class="lg:px-8 md:px-6">
                <img src="@/assets/tele/sudani-logo.png" class="my-2 px-2" alt="logo" />
              </div>
            </div>
            <div
              class="form-group w-1/2 rounded-md p-6 relative bg-[#111220] flex flex-col items-center justify-center px-4 cursor-pointer"
              :class="selectedProvider == 'zain' ? ' border-2 border-[#9d2890]' : ''"
              @click="showOperator(1)"
            >
              <span
                v-if="selectedProvider === 'zain'"
                class="size-5 bg-[#9d2890] absolute top-2 right-2 rounded-full text-center md:text-md text-sm"
                ><span class="mt-3">{{ "✓" }}</span></span
              >
              <label for="zain" class="custom-radio-label mx-2">زين </label>
              <div class="lg:px-8 md:px-6">
                <img src="@/assets/tele/zain.png" class="my-2 px-2" alt="logo" />
              </div>
            </div>
          </div>
          <div
            class="my-3 flex justify-between space-x-3 md:w-10/12 w-full md:px-12 px-1"
            v-if="operator === 'sa'"
          >
            <div
              class="form-group w-1/2 rounded-md p-6 relative bg-[#111220] flex flex-col items-center justify-center px-8 cursor-pointer"
              :class="selectedProvider == 'mobiley' ? ' border-2 border-[#9d2890]' : ''"
              @click="showOperator(2)"
            >
              <span
                v-if="selectedProvider === 'mobiley'"
                class="size-5 bg-[#9d2890] absolute top-2 right-2 rounded-full text-center md:text-md text-sm"
                ><span class="mt-3">{{ "✓" }}</span></span
              >

              <label for="stc" class="custom-radio-label mx-2 my-4">موبايلي </label>

              <img src="@/assets/tele/mobily.png" class="my-2" alt="logo" />
            </div>
            <div
              class="form-group w-1/2 rounded-md p-6 relative bg-[#111220] flex flex-col items-center justify-center px-8 cursor-pointer"
              :class="selectedProvider == 'stc' ? ' border-2 border-[#9d2890]' : ''"
              @click="showOperator(3)"
            >
              <span
                v-if="selectedProvider === 'stc'"
                class="size-5 bg-[#9d2890] absolute top-2 right-2 rounded-full text-center md:text-md text-sm"
                ><span class="mt-3">{{ "✓" }}</span></span
              >

              <label for="stc" class="custom-radio-label mx-2 my-4">STC </label>

              <img src="@/assets/tele/stc.webp" class="my-2" alt="logo" />
            </div>
          </div>
          <div
            class="my-3 flex justify-between space-x-3 md:w-10/12 w-full md:px-12 px-1"
            dir="rtl"
            v-if="operator === 'ae'"
          >
            <div
              class="form-group w-1/2 rounded-md p-6 relative bg-[#111220] flex flex-col items-center justify-center px-8 cursor-pointer"
              :class="selectedProvider == 'etisalate' ? ' border-2 border-[#9d2890]' : ''"
              @click="showOperator(4)"
            >
              <span
                v-if="selectedProvider === 'etisalate'"
                class="size-5 bg-[#9d2890] absolute top-2 right-2 rounded-full text-center md:text-md text-sm"
                ><span class="mt-3">{{ "✓" }}</span></span
              >

              <label for="stc" class="custom-radio-label mx-2 my-4">إتصالات </label>

              <img src="@/assets/tele/etisalat.webp" class="my-2" alt="etisalat" />
            </div>
          </div>
        </div>
      </div>
      <div class="m-3 pb-6 relative md:w-8/12 md:mt-2 mt-1 mx-auto">
        <button
          @click.prevent="redirectUserToProvider"
          class="inline-flex justify-center items-center gap-2 w-full bg-[#9d2890] text-gray-100 rounded-md py-3"
        >
          <span v-show="!loaded"> المتابعة</span>
          <div role="status" v-show="loaded">
            <spinner-view />
          </div>
        </button>
      </div>
    
    </div>
  </div>
</template>

<script>
import SpinnerView from "@/components/SpinnerView.vue";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";

export default {
  components: { SpinnerView },
  data() {
    return {
      loaded: false,
      isMobile: false,
      selectedProvider: null,
      StripePlan: null,
      operator: "sd",
      toast: useToast(),
      tabs: [
        { name: "mobile", label: "إشتراك يومي" },
        { name: "stripe", label: "اشتراك شهري/سنوي" },
      ],
      stirpeOptions: {
        monthly: "https://subscription.systech-digital.com/b/3csfZn1wd3tleUEaEK",
        yearlyOption: "https://subscription.systech-digital.com/b/7sIdRfgr75Bt5k43cf",
        halfYear: "https://subscription.systech-digital.com/b/14keVj4Ip5Bt6o86os",
      },
      providers: {
        sudani: "https://api.nasna.tv/api/auth/sd/subscriptions/redirect",
        zain: "https://dsplp.sd.zain.com/?p=7789312591",
        mobiley: "https://sa.nasna.tv/subscribe/headerinerchment",
        stc: "https://stc.nasna.tv/subscribe/sms",
        etisalate: "https://ae.nasna.tv/",
      },
      activeTab: "stripe",
      yearly: null,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["subscribeAction"]),
    selectedPlan(option) {
      this.StripePlan = option;
    },
    async subscribe() {
      this.loaded = true;

      await this.subscribeAction()
        .then((initPaymentResult) => {
          this.redirectToLp(initPaymentResult);
        })
        .catch((error) => {
          console.log(error);
        });

      this.loaded = false;
    }, //end of subscrbtion method
    redirectToLp(initPaymentResult) {
      const { data } = initPaymentResult;

      if (data.success && data.fullRedirectURL) {
        window.location.href = data.fullRedirectURL;
      }
    },

    showOperator(id) {
      switch (id) {
        case 0:
          this.selectedProvider = "sudani";
          break;
        case 1:
          this.selectedProvider = "zain";
          break;
        case 2:
          this.selectedProvider = "mobiley";
          break;
        case 3:
          this.selectedProvider = "stc";
          break;
        case 4:
          this.selectedProvider = "etisalate";
          break;
        default:
          this.selectedProvider = "sudani";
      }
    },
    redirectUserToProvider() {
      this.loaded = true;
      this.isMobile = true;
      if (this.isMobile) {
        if (this.selectedProvider && this.selectedProvider == "mobiley") {
          this.subscribe();
        } else if (this.providers[this.selectedProvider]) {
          window.location.href = this.providers[this.selectedProvider];
        } else {
          this.toast.error("احتر الباقة المناسبة ");
        }
      } else if (this.StripePlan) {
        if (this.stirpeOptions[this.StripePlan]) {
          window.location.href = this.stirpeOptions[this.StripePlan];
        } else {
          this.toast.error("احتر الباقة المناسبة ");
        }
      } else {
        this.toast.error("احتر الباقة المناسبة ");
      }
      this.loaded = false;
    },
    selectTab(tabName) {
      this.activeTab = tabName;
      this.isMobile = this.activeTab == "mobile" ? true : false;
      console.log(this.isMobile);
    },
  },
  created() {
    this.isMobile = this.activeTab == "mobile" ? true : false;
    console.log(this.isMobile);
  },
};
</script>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}

.xxsm {
  width: 255px;
}

.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #9d2890;
}

.tab {
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: #fff;
  border-radius: 20px;
  color: #9d2890;
}
</style>
