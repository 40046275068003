import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFound.vue'
import LoginView from '../views/LoginView.vue'
import SubscribeDiffrent from '../views/SubscribeDiffrent.vue'
import SubscribeView from '../views/SubscribeView.vue'
import WatchView from '../views/WatchView.vue'
import WatchNow from '../views/WatchNow.vue'
import BrowsPrograms from '../views/brows/BrowsPrograms.vue'
import BrowsVideos from '../views/brows/BrowsVideos.vue'
import BrowsChannels from '../views/brows/BrowsChannels.vue'
import LiveView from '../views/brows/LiveView.vue'
import SearchResults from '../views/brows/SearchResults.vue'
import CategoriesView from '../views/CategoriesView.vue'
import CatDetails from '../views/brows/CatDetails.vue'
import SlidersView from '../views/SlidersView.vue'
import AudioPlayer from '../views/AudioPlayer.vue'

import SuccessView from '../views/response/SuccessView.vue'
import FailureView from '../views/response/FailureView.vue'
import SuccessOk from '../views/SuccessOk'
import ManageView from '../views/ManageView'
import TermsView from '../views/brows/TermsView.vue'
import ProfileStatus from '../views/ProfileStatus'
import UnsubscribedSuccess from '../views/UnsubscribedSuccess'
import ConfirmLogout from '../views/ConfirmLogout'
import ConfirmUnsubscribe from '../views/ConfirmUnsubscribe'
import CallBack from '../views/response/CallBack'
import VerifyOtp from '../views/VerifyOtp.vue'
import SubscriptionsView from '../views/SubscriptionsView.vue'
import OperatorsView from '@/views/OperatorsView.vue'

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/subscribe/headerinerchment',
    name: 'subscribe.header',

    component: SubscribeView,
    meta: {
      footer: false,
      auth: false
    }
  },

  {
    path: '/user/check/:session_id',
    name: 'user.login',
    component: SuccessOk,
    meta: {
      auth: false
    }
  },
  {
    path: '/user/manage',
    name: 'user.manage',
    component: ManageView,
    meta: {
      auth: false
    }
  },
  {
    path: '/user/subscribe',
    name: 'subscribe',

    component: SubscribeDiffrent,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/user/verify-opt',
    name: 'user.verify-opt',

    component: VerifyOtp,
    meta: {
      footer: false,
      auth: false
    }
  }
  ,
  {
    path: '/user/subscriptions',
    name: 'user.subscriptions',

    component: SubscriptionsView,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/operators/subscriptions',
    name: 'users.subscriptions',

    component: OperatorsView,
    meta: {
      footer: false,
      auth: false
    }
  }
  ,
  {
    path: '/unsubscribed',
    name: 'unsubscribe',

    component: UnsubscribedSuccess,
    meta: {
      footer: false,
      auth: false
    }
  }
  ,
  {
    path: '/confirm/logout',
    name: 'confirm.logout',

    component: ConfirmLogout,
    meta: {
      footer: false,
      auth: false
    }
  }
  ,
  {
    path: '/confirm/unsubscribe',
    name: 'confirm.unsubscribe',

    component: ConfirmUnsubscribe,
    meta: {
      footer: false,
      auth: false
    }
  }
  ,
  {
    path: '/profile',
    name: 'profile',

    component: ProfileStatus,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/user/login',
    name: 'login',

    component: LoginView,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/success',
    name: 'success',

    component: SuccessView,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/failed',
    name: 'failed',

    component: FailureView,
    meta: {
      footer: false,
      auth: false
    }
  },
  {
    path: '/watch/:id',
    name: 'watch',

    component: WatchView,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/watch/now/:id',
    name: 'watch.now',
    component: WatchNow,
    meta: {
      footer: true,
      auth: false
    }
  },
  {
    path: '/brows/programs',
    name: 'brows.programs',
    component: BrowsPrograms,
    meta: {
      footer: true,
      auth: false
    }
  }
  ,
  {
    path: '/brows/videos',
    name: 'brows.videos',

    component: BrowsVideos,
    meta: {
      footer: true,
      auth: false
    }
  }
  ,

  {
    path: '/brows/channels',
    name: 'brows.channels',

    component: BrowsChannels,
    meta: {
      footer: true,
      auth: false
    }
  }
  ,
  {
    path: '/brows/live/:id',
    name: 'brows.live',

    component: LiveView,
    meta: {
      footer: true,
      auth: false
    }
  }
  ,
  {
    path: '/brows/search/:query',
    name: 'results',
    component: SearchResults,
    meta: {
      footer: true,
      auth: false
    }
  }
  ,
  {
    path: '/categories',
    name: 'categories',

    component: CategoriesView,
    meta: {
      footer: true,
      auth: false
    }
  }
  ,
  {
    path: '/categories/show/:id',
    name: 'categories.show',

    component: CatDetails,
    meta: {
      footer: true,
      auth: false
    }
  }
  ,
  {
    path: '/sliders',
    name: 'sliders',

    component: SlidersView
  },
  {
    path: '/audio',
    name: 'audio',

    component: AudioPlayer
  },

  {
    path: '/terms',
    name: 'terms',

    component: TermsView
  },
  {
    path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound, meta: {
      auth: false
    }
  },
  {
    path: '/auth/:provider/callback',
    name: 'auth.provider.callback',
    component: CallBack
  }

]
